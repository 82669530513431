import oucy from "@/util/oucyUtil";
import { localSet, localGet, localDel } from "@/store/store"
import QRCode from 'qrcodejs2'
export default {
    name: "payOrder",
    data() {
        return {
            pageWidth: oucy.pageWidth,
            checked: 1,
            radio: 1,
            num: 1,
            dialogVisible: '',
            dialogVisible222: '1',
            input: '',
            textarea: '',
            qrCode: null,
            payExpSeconds: null,

            tmpOrder: {}
        }
    },
    mounted() {
        this.orderId = this.$route.query.orderId
        this.tmpOrder = localGet('tmpOrder')
        this.creatQrCode(this.tmpOrder.order.qrCode)
        console.log('this.tmpOrder', this.tmpOrder.order.payExpSeconds);
            this.payExpSeconds = this.tmpOrder.order.payExpSeconds
        setTimeout(() => {
            this.$refs.countDown.myinit()
        })
        // console.log(this.$oucy.getUUID());
        this.eventBus.$off(this.$oucy.msgKeys.SPU_PAY_SUCCESS).$on(this.$oucy.msgKeys.SPU_PAY_SUCCESS, (res) => {
            console.log('支付事件：');
            console.log(res);
            this.$alert('支付成功')
            // this.$oucy.back()
            this.$oucy.go('/acc/OMS')

        })
    },
    methods: {
        finishOrder() {
            oucy.replace('/acc/finishOrder')
        },
        handleChange(value) {
            console.log(value);
        },
        setCurrent(row) {
            this.$refs.singleTable.setCurrentRow(row);
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
        creatQrCode(text) {
            const me = this;
            if (me.qrCode == null) {
                me.qrCode = new QRCode(this.$refs.myLoginQrCodeUrl, {
                    text: text,
                    // text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfPreLogin+'?p='+me.$oucy.getUUID(),
                    width: 146,
                    height: 146,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })

                console.log('creatQrCode')
            }
        },
        updateTime(v){
            let payExpSeconds=v.hour*60*60+v.minute*60+v.sec
            this.tmpOrder.order.payExpSeconds=payExpSeconds
            payExpSeconds%2==0&& localSet('tmpOrder',this.tmpOrder)
        },
        stopTime(v){
            this.tmpOrder.order.payExpSeconds=0
            localSet('tmpOrder',this.tmpOrder)
        },
    }
}