<template>
    <div>
        <div class="container">
            <div class="pageCenter p-10">
                <el-container>
                    <div style="padding: 0 0 50px;width: 100%">
                        <h3>请支付订单</h3>
                        <!-- 有货 -->
                        <div class="content">
                            <div class="text-left">订单信息</div>
                            <div class="contentCenter">
                                <div v-if="1">
                                    <div class="table">
                                        <div class="tableHeader m-t-25">
                                            <div class="cell-2">商品信息</div>
                                            <div class="cell-3">单价</div>
                                            <div class="cell-4">数量</div>
                                            <div class="cell-5">优惠</div>
                                            <div class="cell-6">小计</div>
                                        </div>
                                        <div class="tableBody">
                                            <div v-for="(v,i) of tmpOrder.stockEnterprise" :key="i">
                                                <div class="itemsHeader f14 m-t-25"><img src="@/assets/images/stockCar/merchant_icon.png" class="m-r-5">商家：<span>{{v.enterpriseName}}</span></div>
                                                <div class="items m-t-10">
                                                    <div class="item p-10" v-for="(vv,ii) of v.stockItems" :key="ii">
                                                        <!--                                                         <div class="cell-1">
                                                            <span class="checkboxLose" v-if="v==1">失效</span>
                                                            <el-checkbox v-model="checked" v-else></el-checkbox>
                                                        </div> -->
                                                        <div class="cell-2">
                                                            <div class="merchandise">
                                                                <div class="merchandiseImg"><img :src="$oucy.ossUrl + vv.itemAvata"></div>
                                                                <div class="merchandiseText p-l-10">
                                                                    <div class="name c-1 14 line-2">{{vv.itemName}}</div>
                                                                    <div class="specification c-2 m-t-15 f12 line-2">规格：{{vv.itemAttrDesc}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="cell-3"><span>￥{{vv.itemPrise}}</span></div>
                                                        <div class="cell-4">
                                                            <span class="f12">{{vv.itemCount}}</span>
                                                        </div>
                                                        <div class="cell-5">
                                                        </div>
                                                        <div class="cell-6 price">￥{{vv.itemCount*vv.itemPrise}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center " style="margin-top:86px">
                                <div>
                                    <div class="df df-ai-c df-jc-c">
                                        <div>
                                            <div ref="myLoginQrCodeUrl" class="payQRCode"></div>
                                            <!-- <img src="@/assets/QRCode/purchase_code.png" class="payQRCode"> -->
                                            <div class="f12 c-1 m-t-15">请使用
                                                <span v-if="tmpOrder.ConfirmOrderScheme && tmpOrder.ConfirmOrderScheme.schemeOrderPayChennel==0">微信</span>
                                                <span v-if="tmpOrder.ConfirmOrderScheme && tmpOrder.ConfirmOrderScheme.schemeOrderPayChennel==1">支付宝</span>
                                                扫码付款</div>
                                        </div>
                                        <div class="text-left m-l-30">
                                            <div class="c-1 f16">本次交易供需支付:</div>
                                            <div class="price f24 m-t-15">￥{{(tmpOrder.payFee*1+tmpOrder.postFee*1).toFixed(2)}}</div>
                                            <div class="c-1 f16 m-t-20">剩余支付时间 <span class="time" style="color: red;font-size: 22px">
                                                    <countDown ref="countDown" @stopTime="stopTime" @updateTime="updateTime" :s='payExpSeconds'></countDown>
                                                </span>超时订单自动关闭</div>
                                            <!-- <div class="c-1 f12 m-t-20">剩余支付时间 <span class="time">00:15:48</span>超时订单自动关闭</div> -->
                                        </div>
                                    </div>
                                    <div style="margin-top:86px">
                                        <!-- <el-button type="primary" @click="finishOrder">已完成支付</el-button> -->
                                        <el-button @click="$oucy.back()">返回</el-button>
                                    </div>
                                </div>
                                <div v-if="0" class="duringPayment"><img src="@/assets/images/stockCar/await.gif" class="awaitGif">扫码成功，正在支付...</div>
                            </div>
                        </div>
                    </div>
                </el-container>
            </div>
        </div>
    </div>
</template>
<script>
import payOrder from './payOrder.js'
export default payOrder
</script>
<style lang="less" scoped>
@import "./payOrder.less";
</style>